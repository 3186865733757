
import { RevCardCarousel } from '@ds/components/CardCarousel'
import isEmpty from 'lodash/isEmpty'

import ArticleCard from '@article/components/Card'

import BlockTitle from '../private/BlockTitle'

import translations from './ArticleCardsList.translations'

export default {
  components: {
    BlockTitle,
    ArticleCard,
    RevCardCarousel,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    guides: {
      type: Array,
      default: () => [],
    },
    subtitle: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    tracking: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    translations: () => translations,
    hasGuides() {
      return !isEmpty(this.guides)
    },
  },
  methods: {
    trackingDataByCard({ category, title }) {
      return {
        ...this.tracking,
        name: title,
        value: {
          type: this.tracking.value,
          articleCategory: category,
        },
      }
    },
  },
}
