
import { RevEditorialCard } from '@ds/components/EditorialCard'
import isEmpty from 'lodash/isEmpty'

import { trackClick } from '@tracking/events'

import { DATA_SELECTOR_ATTRIBUTE_FOR_SEO } from './Card.constants'
import translations from './Card.translations'

export default {
  components: {
    RevEditorialCard,
  },
  props: {
    alt: {
      type: String,
      default: '',
    },
    category: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      required: true,
    },
    imageRetina: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    titleKicker: {
      type: String,
      default: '',
    },
    titleTag: {
      type: String,
      required: true,
    },
    link: {
      type: Object,
      default: () => {},
    },
    slug: {
      type: String,
      default: '',
    },
    tracking: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    translations: () => translations,
    dataSelectorAttribute: () => DATA_SELECTOR_ATTRIBUTE_FOR_SEO,
    renderedImage() {
      return !isEmpty(this.imageRetina) ? this.imageRetina : this.image
    },
  },
  methods: {
    sendMarketingData() {
      const trackingDataDefault = {
        name: this.title,
        zone: this.$route.name,
        value: {
          articleCategory: this.category,
        },
      }
      const trackingData = !isEmpty(this.tracking)
        ? this.tracking
        : trackingDataDefault
      trackClick(trackingData)
    },
  },
}
